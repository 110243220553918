<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('bundle_and_device.bundle_and_device') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('bundle_and_device.item') }}</label>
                <!--              <div class="input-group mb-3">-->
                <div class="d-flex search-item-group">
                  <select name="" id="item" v-model="f_by" class="custom-select" style="width: 100px;">
                    <option value="name" selected>{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect v-model="item"
                               id="item_id"
                               :placeholder="$t('bundle_and_device.item')"
                               :label="f_by?f_by:'sku_code'"
                               track-by="id"
                               :options="items"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :internal-search="false"
                               @input="getSubItems"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
                <!--              <div class="input-group-prepend">-->
                <!--                                        <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>-->
                <!--                                      </div>-->
                <!--                                    </div>-->
                <span v-if="validation && validation.composite_item_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.composite_item_id[0] }}
              </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('bundle_and_device.item_details') }}</label>
                <div class="d-flex">
                  <img v-if="item && item.image_url" :src="item.image_url" style="width: 70px; height: 70px;" alt="">
                  <div class="pl-2 pr-2 text-dark">
                    <p class="mb-1" v-if="item && item.sku_code">{{ $t('items.sku_code') }}: {{ item.sku_code }}</p>
                    <p class="mb-1" v-if="item && item.name">{{ $t('items.name') }}: {{ item.name }}</p>
                    <p class="mb-1" v-if="item && item.sale_price">{{ $t('items.sale_price') }}: {{ item.sale_price }}</p>
                    <p class="mb-1" v-if="item && item.purchase_price">{{ $t('items.purchase_price') }}:
                      {{ item.purchase_price }}</p>
                    <p class="mb-1" v-if="item && item.sum_available_qty">{{ $t('items.sum_available_qty') }}:
                      {{ item.sum_available_qty }}</p>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('bundle_and_device.bundle_code') }}</label>
                <input type="text" v-model="data.code" class="form-control"
                       :class="validation && validation.code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.code[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('bundle_and_device.bundle_date') }}</label>
                <input type="date" v-model="data.date" class="form-control"
                       :class="validation && validation.date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.date[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('bundle_and_device.from_inventory') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect v-model="from_inventory"
                               :placeholder="$t('bundle_and_device.from_inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.from_inventory_id ? 'is-invalid' : ''"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="selectFromInventory">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.from_inventory_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.from_inventory_id[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('bundle_and_device.target_inventory') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect v-model="target_inventory"
                               :placeholder="$t('bundle_and_device.target_inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.target_inventory_id ? 'is-invalid' : ''"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="selectTargetInventory">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.target_inventory_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.target_inventory_id[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('bundle_and_device.bundle_quantity') }}<span class="text-danger">*</span></label>
                <input type="number" min="1" @input="setQuantity($event)" v-model="data.quantity" class="form-control"
                       :class="validation && validation.quantity ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.quantity" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.quantity[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="f_status" v-model="data.status" type="text" class="custom-select"
                        :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.status[0] }}
              </span>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('bundle_and_device.associated_quantity') }}</h6>
          </div>
          <div class="bg-white">
            <table class="table table-row-bordered">
              <thead>
              <tr>
                <th width="250px">{{ $t('bundle_and_device.item_details') }}</th>
                <th width="300px">{{ $t('bundle_and_device.item') }}</th>
                <th width="200px">{{ $t('bundle_and_device.origin_quantity') }}</th>
                <th>{{ $t('bundle_and_device.required_quantity') }}</th>
                <th width="200px">{{ $t('bundle_and_device.inventory') }}</th>
                <th>{{ $t('bundle_and_device.available_quantity') }}</th>
              </tr>

              </thead>
              <tbody>
              <template v-if="sub_items.length > 0">
                <tr v-for="(row, index) in sub_items" :key="index">
                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item"
                       class="d-flex cursor-pointer">
                      <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}:
                          {{ row.item.sku_code }}</p>
                        <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                        <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}:
                          {{ row.item.sale_price }}</p>
                        <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}:
                          {{ row.item.purchase_price }}</p>
                        <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}:
                          {{ row.item.sum_available_qty }}</p>
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="d-flex search-item-group">
                      <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                        <option value="name" selected>{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>
                      <multiselect v-model="row.item"
                                   :placeholder="$t('bundle_and_device.item')"
                                   :label="row.f_by? row.f_by:'name'"
                                   track-by="id"
                                   :options="items"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :internal-search="false"
                                   @search-change="getItems(row.f_by, $event)">
                      </multiselect>
                    </div>

                  </td>

                  <td><input v-model="row.origin_quantity" type="number" disabled class="form-control"></td>
                  <td><input v-model="row.required_quantity" type="number" disabled class="form-control"></td>
                  <td>
                    <multiselect v-model="row.inventory"
                                 :placeholder="$t('bundle_and_device.inventory')"
                                 label="name"
                                 track-by="id"
                                 :options="inventories"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @input="getAvailableQtyForItemDependentOnInventoryRepeater(index)">
                    </multiselect>
                  </td>
                  <td><input v-model="row.available_quantity" type="number" disabled class="form-control"></td>


                  <!--                    <td>-->
                  <!--                      <v-icon style="color: #dc3545;" small v-if="sub_items.length > 1" @click="removeRepeater(index)">-->
                  <!--                        mdi-delete-->
                  <!--                      </v-icon>-->

                  <!--                    </td>-->
                </tr>
              </template>
              <tr v-else>
                <td colspan="3">
                  {{ $t('no_items') }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('bundle_and_device.notes') }}</label>
                  <textarea v-model="data.notes" class="form-control"
                            :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.notes[0] }}
              </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>


      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "form-bundle-and-device",
  data() {
    return {
      mainRoute: 'items/item_bundles',
      mainRouteDependency: 'base/dependency',


      data: {
        composite_item_id: null,
        from_inventory_id: null,
        target_inventory_id: null,
        code: null,
        quantity: null,
        date: null,
        notes: null,
        status: 1,
      },

      sub_items: [],
      sub_item_repeater: {
        id: null,
        item_id: null,
        item: null,
        inventory: null,
        inventory_id: null,
        origin_quantity: null,
        required_quantity: null,
        available_quantity: null
      },


      item: null,
      items: [],
      f_by: 'name',

      from_inventory: null,
      target_inventory: null,
      inventories: [],
      status_list: [],
      idEditing: this.$route.params.id,
      isEditing: false,

      validation: null,


    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    item: function (val) {
      if (val)
        this.data.composite_item_id = val.id;
      else
        this.data.composite_item_id = null;
    },
  },
  methods: {
    ...timeZoneStructure,
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        sub_items: this.sub_items,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'bundle-and-device.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        sub_items: this.sub_items,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'bundle-and-device.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.composite_item_id = response.data.data.composite_item_id;
        this.data.from_inventory_id = response.data.data.from_inventory_id;
        this.data.target_inventory_id = response.data.data.target_inventory_id;
        this.data.code = response.data.data.code;
        this.data.quantity = response.data.data.quantity;
        this.data.date = response.data.data.date;
        this.data.notes = response.data.data.notes;
        this.data.status = response.data.data.status;

        this.item = response.data.data.item;
        this.from_inventory = response.data.data.from_inventory;
        this.target_inventory = response.data.data.target_inventory;

        // if (response.data.data.sub_items && response.data.data.sub_items.length > 0) {
        this.sub_items = response.data.data.sub_items ? response.data.data.sub_items : [];
        // } else {
        //   this.addRepeater();
        // }


      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {
          params: {
            [_f_by]: filter,
            item_type: 2
          }
        }).then((response) => {
          this.items = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },

    addRepeater() {
      this.sub_items.push(this.sub_item_repeater);
      this.sub_item_repeater = {
        id: null,
        item_id: null,
        item: null,
        inventory: null,
        inventory_id: null,
        origin_quantity: null,
        required_quantity: null,
        available_quantity: null
      };
    },
    removeRepeater(index = 0) {
      if (this.sub_items.length > 1)
        this.sub_items.splice(index, 1);
    },
    selectFromInventory(event) {
      if (event) {
        this.data.from_inventory_id = event.id;
        this.sub_items = this.sub_items.map((row) => {
          row.inventory = event
          row.inventory_id = event.id;
          return row;
        });
        this.getAvailableQtyDependentOnInventory();
      } else {
        this.data.from_inventory_id = null;
      }
    },
    selectTargetInventory(event) {
      if (event) {
        this.data.target_inventory_id = event.id;
        this.sub_items = this.sub_items.map((row) => {
          row.inventory = event
          row.inventory_id = event.id;
          return row;
        });
      } else {
        this.data.target_inventory_id = null;
      }
    },
    setQuantity(event) {
      let _qty = (event && event.target.value) ? event.target.value : 0;
      this.sub_items = this.sub_items.map((row) => {
        row.required_quantity = (_qty ? parseInt(_qty) : 0) * (row.origin_quantity ? parseInt(row.origin_quantity) : 0);
        // if (row.required_quantity > row.available_quantity) {
        //   row.required_quantity = row.available_quantity;
        // }
        return row;
      });
    },
    reCalcForQuantity() {
      let _qty = this.data.quantity ? this.data.quantity : 0;
      this.sub_items = this.sub_items.map((row) => {
        row.required_quantity = (_qty ? parseInt(_qty) : 0) * (row.origin_quantity ? parseInt(row.origin_quantity) : 0);
        // if (row.required_quantity > row.available_quantity) {
        //   row.required_quantity = row.available_quantity;
        // }
        return row;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/bundle_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getSubItems(event) {
      if (event && event.id) {
        ApiService.get(this.mainRouteDependency + "/composite_item/" + event.id,{params: {inventory_id: this.data.from_inventory_id}}).then((response) => {
          this.sub_items = response.data.data.map((row) => {
            return {
              id: null,
              item_id: row.item_id,
              item: row.item,
              inventory: this.from_inventory ? this.from_inventory : null,
              inventory_id: this.from_inventory ? this.from_inventory.id : null,
              origin_quantity: row.origin_quantity,
              required_quantity: (this.data.quantity ? parseInt(this.data.quantity) : 0) * (row.origin_quantity ? parseInt(row.origin_quantity) : 0),
              available_quantity: row.available_quantity
            }
          });
          this.getAvailableQtyDependentOnInventory();
        });
      }

    },
    getAvailableQtyDependentOnInventory() {
      let _inventory_id = this.data.from_inventory_id;
      let _items_ids = [];
      this.sub_items.forEach((row) => {
        if (row.item && row.item.id)
          _items_ids.push(row.item.id)
      });
      if (_items_ids.length > 0 && _inventory_id) {
        ApiService.query(this.mainRouteDependency + `/getQtyInventory/${_inventory_id}`, {ids: _items_ids}).then((response) => {
          this.sub_items = this.sub_items.map((row) => {
            let _item = response.data.data.find(it => it.product_id == row.item.id)
            if (_item && row.item)
              row.available_quantity = ((_item.available_stock_qty > 0) ? _item.available_stock_qty : 0);
            else
              row.available_quantity = 0;
            return row;
          });
          this.reCalcForQuantity();
        });
      } else {
        return 0;
      }

    },

    getAvailableQtyForItemDependentOnInventoryRepeater(index) {
      let _row = this.sub_items[index];
      let _items_ids = [];
      let _inventory_id = null;
      if (_row) {
        _items_ids.push(_row.item.id);
        _inventory_id = _row.inventory.id;
      }
      if (_items_ids.length > 0 && _inventory_id) {
        ApiService.query(this.mainRouteDependency + `/getQtyInventory/${_inventory_id}`, {ids: _items_ids}).then((response) => {
          let _res = response.data.data[0];
          if (_res) {
            this.sub_items[index].available_quantity = ((_res.available_stock_qty > 0) ? _res.available_stock_qty : 0);
          }
          this.reCalcForQuantity();
        });
      }
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/21").then((response) => {
        this.data.code = response.data.data.code;
      });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {

        this.data.from_inventory_id = response.data.data.inventory_id;
        this.from_inventory = response.data.data.inventory;
        this.data.target_inventory_id = response.data.data.inventory_id;
        this.target_inventory = response.data.data.inventory;

        this.sub_item_repeater.inventory = response.data.data.inventory;
        this.sub_item_repeater.inventory_id = response.data.data.inventory_id;

        if (this.sub_items[0]) {
          this.sub_items[0].inventory = response.data.data.inventory;
          this.sub_items[0].inventory_id = response.data.data.inventory_id;
        }
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.bundle_and_device"),
      route: '/items/bundle-and-device'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getInventories();
    this.getStatus();

    if (this.idEditing) {
      this.getData();
    } else {
      this.getCode();
      this.defaultDataForUser();
      // this.data.date = new Date().toISOString().slice(0, 10);
      // this.addRepeater();
      this.getTimeZone().then((res)=>{
        this.data.date = res;
      });
    }

  },
};
</script>